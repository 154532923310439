import React from "react";
import Header from '../component/header'
import Footer from '../component/footer'
import utils from '../utils/constants'


const ProductDetails = () => {
    let params = new URL(document.location.toString()).searchParams;
    let _id = params.get("id");
    const product_id = _id.split('')[0]
    const item = utils.products.find(x => x.productId == product_id).items?.find(c => c.id == _id)

    const allitems = utils.products.map(a => a.items).flat()?.filter(b => b.id != _id)
    console.log("🚀 ~ ProductDetails ~ allitems:", allitems)
    const similar = allitems.sort(() => Math.random() - Math.random()).slice(0, 6);
    console.log("🚀 ~ ProductDetails ~ similar:", similar)

  return (
    <>
    <Header/>
    <section class="banner" style={{backgroundImage: 'url(assets/img/patron-black.png)'}}>
        <div class="container">
            <div class="row align-items-center">
                
            </div>
        </div>
    </section>
    <section class="featured-dishes-product-detail-img" style={{paddingTop: 80}}>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6">
                    <div class="featured-dishes product-detail-img" style={{padding: 0}}>
                    <div class="featured-dishes-img">
                        <img class="pd-img" src={item.image} alt={item.name}/>
                    </div>
                    <ul class="instagram-posts">
                        <li>
                        <a href={item.image} data-fancybox="gallery"><figure><img alt="girl" src={item.image}/></figure></a>
                        </li>
                        <li>
                        <a href={item.image} data-fancybox="gallery"><figure><img alt="girl" src={item.image}/></figure></a>
                        </li>
                        <li>
                        <a href={item.image} data-fancybox="gallery"><figure><img alt="girl" src={item.image}/></figure></a>
                        </li>
                        <li>
                        <a href={item.image} data-fancybox="gallery"><figure><img alt="girl" src={item.image}/></figure></a>
                        </li>
                        <li>
                        <a href={item.image} data-fancybox="gallery"><figure><img alt="girl" src={item.image}/></figure></a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="product-info ">
                    <h3>
                        {item.name}
                    </h3>
                    <div class="variations_form">
                        <p>{item.description}</p>
                    </div>
                    </div>
                    <div class="row align-items-center discover-menu">
                        <div class="discover">
                                <h4>Nutritional Information</h4>
                                <p>Serving Size 30g / Serving Per Pack 5* / Amount Per Serving*</p>
                                <ul>
                                    <li>
                                            <div class="col-6">
                                                <h6></h6>
                                            </div>
                                            <p class="col-3 text-center">Per 30g</p>
                                            <p class="col-3 text-center">% Daily Value**</p>
                                        </li>    
                                    {item.ingredients.map(ing => 
                                        <li>
                                            <div class="col-6">
                                                <h6>{ing.item}</h6>
                                            </div>
                                            <span class="col-3 text-center">{ing.serving}</span>
                                            <span class="col-3 text-center">{ing.per}</span>
                                        </li>
                                    )}
                                </ul>
                                <p style={{marginTop: 20, fontSize: 12}}>*Approximate Values</p>
                                <p style={{fontSize: 12, fontWeight: 'normal', lineHeight: 1.4}}>**The % Daily value tells you how much nutrient in a serving of food
                                contributes to daily diet. 2,000 Kcal a day is used for general nutrition advice for Adults. Your Values may be higher or lower depending on your calorie needs=</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="benefits">
                <div>
                    <h2 class="pb-3">Benefits</h2>
                    <ul class="quality-foods">
                       {utils.products[0].benefits.map(item => <li><img alt="img" src="assets/img/check.png"/><h6>{item}</h6></li>)} 
                    </ul>
                </div>
                <div class="benefit-img">
                    <img alt="benefit" src="assets/img/benefits_banner.jpg"/>
                </div>
            </div>
        </div>
    </section>
    <section class="gap">
        <div class="container">
            <div class="follow p-0">
                <h2>Other Products</h2>
                <p>Explore more from our range of products</p>
            </div>
            <div class="gallerydata owl-carousel owl-theme">
                {similar.map(item => 
                    <div class="gallery-img-style">
                        <div class="featured-imagebox featured-imagebox-gallery">
                            <a href={`/product-details?id=${item.id}`}>
                                <div class="featured-link">
                                    <div class="featured-thumbnail">                                                
                                        <img class="img-fluid" src={item.image} alt={item.name}/>
                                        <h6 style={{fontSize: 14, marginTop: 20, textAlign: 'center'}}>{item.name}</h6>
                                    </div>
                                    <div class="featured-overlay"></div>
                                </div>
                            </a>
                        </div>
                    </div>)}
            </div>
        </div>
    </section>
    <Footer/>
    </>
  );
};

export default ProductDetails;
