import React from "react";
import Header from '../component/header'
import Footer from '../component/footer'


const About = () => {

  return (
    <>
    <Header/>
    
    <Footer/>
    </>
  );
};

export default About;
