import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home'
import ProductDetails from './pages/productDetails'
import About from './pages/about';

function App() {
  return (
    <Router>
        <Routes>
			    <Route exact path="/" element={<Home/>} />
			    <Route exact path="/product-details" element={<ProductDetails/>} />
			    <Route exact path="/about-us" element={<About/>} />
        </Routes>
		</Router>
  );
}

export default App;
