const products = [
    {
        productId : 1,
        productType : 'Dosa',
        title : 'Why Not Dosa',
        subtitle : 'Ready to eat dosa in a new avatar',
        description : 'Experience Authentic Flavor in Every Bite: Ready-to-Eat Dosas for a Gourmet Adventure!',
        productImage : 'assets/img/banner_dosa.png',
        benefits: ["Premium Ingredients for Superior Taste",
                    "Healthy Snacking Alternative",
                    "Unique and Bold Flavor Selection",
                    "All-Natural and High-Quality Ingredients",
                    "Perfect for Any Occasion",
                    "Guilt-Free Indulgence",
                    "Crafted with Care"],

        items : [
            { id : 11, name : 'Mexican Cheese Salsa Dosa', image : 'assets/img/items/dosa/pd_mexican-cheese-salsa.png',
                description: "Why Not Foods' Mexican Cheesy Salsa Dosa brings a global twist to a South Indian classic. This ready-to-eat crispy dosa is infused with the bold flavors of Mexican salsa and gooey cheese , offering a zesty, cheesy experience with every bite. No cooking required—just open and enjoy the perfect fusion of spicy Mexican flair with authentic dosa crispness. Ideal for snacking or a quick meal, this dosa delivers the best of both worlds. Whether you're craving the vibrancy of Mexican street food or the comfort of Indian cuisine, our Mexican Cheesy Salsa Dosa satisfies on all fronts.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '107', per: ''},
                                        {item: 'Protein (g)', serving: '2.84g', per: '6'},
                                        {item: 'Carbohydrates (g) ', serving: '23.40g', per: '8'},
                                        {item: 'Total Sugar (g)', serving: '0g', per: ''},
                                        {item: 'Added Sugars (g)', serving: '0g', per: '0'},
                                        {item: 'Total Fat (g)', serving: '0.28g', per: '0'},
                                        {item: 'Saturated Fat (g)', serving: '0.10g', per: '0'},
                                        {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                        {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                        {item: 'Sodium (mg)', serving: '140.0mg', per: '6'},
                        ]},
            { id : 12, name : 'Noodle Dosa', image : 'assets/img/items/dosa/pd_noodles.png',
                description: "Introducing the ultimate fusion snack— Instant Noodles Dosa from Why Not Foods! This ready-to-eat crispy dosa is filled with the comforting flavors of your favorite instant noodles, delivering a delightful combination of crunch and noodles in every bite. No cooking or preparation required, just grab and enjoy! Perfect for those seeking a quick yet flavorful meal that marries the iconic Indian dosa with the ever-popular instant noodles. Whether it's a late-night craving or a fun snack on the go, this dosa offers a unique, mouthwatering experience that combines nostalgia with convenience.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '105', per: ''},
                                        {item: 'Protein (g)', serving: '2.84g', per: '6'},
                                        {item: 'Carbohydrates (g) ', serving: '23.0g', per: '8'},
                                        {item: 'Total Sugar (g)', serving: '0g', per: ''},
                                        {item: 'Added Sugars (g)', serving: '0g', per: '0'},
                                        {item: 'Total Fat (g)', serving: '0.24g', per: '0'},
                                        {item: 'Saturated Fat (g)', serving: '0.10g', per: '0'},
                                        {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                        {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                        {item: 'Sodium (mg)', serving: '143.0mg', per: '6'},
                        ]},
            { id : 13, name : 'Butter Dosa', image : 'assets/img/items/dosa/pd_butter.png',
                description: "Indulge in the classic richness of Butter Dosa from Why Not Foods. Our ready-to-eat crispy dosa is generously infused with the smooth, creamy taste of butter , enhancing the traditional South Indian flavors. With no cooking needed, this dosa is perfect for a quick meal or a snack that feels indulgent yet light. The irresistible combination of buttery goodness and dosa crispness delivers a delicious experience that transports you straight to the streets of South India. Whether you're short on time or simply craving authentic comfort food, our Butter Dosa is your go-to choice.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '107', per: ''},
                                        {item: 'Protein (g)', serving: '2.85g', per: '6'},
                                        {item: 'Carbohydrates (g) ', serving: '23.20g', per: '8'},
                                        {item: 'Total Sugar (g)', serving: '0g', per: ''},
                                        {item: 'Added Sugars (g)', serving: '0g', per: '0'},
                                        {item: 'Total Fat (g)', serving: '0.31g', per: '0'},
                                        {item: 'Saturated Fat (g)', serving: '0.25g', per: '0'},
                                        {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                        {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                        {item: 'Sodium (mg)', serving: '138.0mg', per: '6'},
                        ]},
            { id : 14, name : 'Cheese Dosa', image : 'assets/img/items/dosa/pd_cheese.png',
                description: "Why Not Foods' Cheese Dosa offers the ultimate blend of South Indian tradition with a cheesy twist. This ready-to-eat crispy dosa is layered with rich, melted cheese , creating a satisfying snack or meal in seconds—no cooking required. The savory combination of gooey cheese and crispy dosa makes for a mouthwatering bite every time. Whether you're a cheese lover or simply looking for a quick yet flavorful option, this dosa delivers comfort and taste in one delicious package. It’s the perfect fusion of indulgence and convenience, ideal for any time of day.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '108', per: ''},
                                        {item: 'Protein (g)', serving: '2.87g', per: '6'},
                                        {item: 'Carbohydrates (g) ', serving: '23.30g', per: '8'},
                                        {item: 'Total Sugar (g)', serving: '0g', per: ''},
                                        {item: 'Added Sugars (g)', serving: '0g', per: '0'},
                                        {item: 'Total Fat (g)', serving: '0.34g', per: '0'},
                                        {item: 'Saturated Fat (g)', serving: '0.20g', per: '1'},
                                        {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                        {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                        {item: 'Sodium (mg)', serving: '135.0mg', per: '6'},
                        ]},
            { id : 15, name : 'Spicy Schezwan Dosa', image : 'assets/img/items/dosa/pd_spicy-schezwan.png',
                description: "For those who love a fiery kick, Why Not Foods’ Spicy Schezwan Dosa is the perfect snack. This ready-to-eat crispy dosa is packed with the bold, tangy heat of Schezwan sauce , blending the best of South Indian crispiness with the spicy flavors of the East. No cooking or preparation required—just open and enjoy the ultimate fusion of flavors. Whether you're craving something adventurous or need a quick meal that delivers on spice and crunch, our Spicy Schezwan Dosa offers an exciting and flavorful experience, sure to satisfy your spicy cravings in minutes.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '106', per: ''},
                                        {item: 'Protein (g)', serving: '2.80g', per: '6'},
                                        {item: 'Carbohydrates (g) ', serving: '23.15g', per: '8'},
                                        {item: 'Total Sugar (g)', serving: '0g', per: ''},
                                        {item: 'Added Sugars (g)', serving: '0g', per: '0'},
                                        {item: 'Total Fat (g)', serving: '0.28g', per: '0'},
                                        {item: 'Saturated Fat (g)', serving: '0.17g', per: '1'},
                                        {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                        {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                        {item: 'Sodium (mg)', serving: '142.0mg', per: '6'},
                        ]},
        ]
    },
    {
        productId : 2,
        productType : 'Popcorn',
        title : 'Why Not Popcorn',
        subtitle : 'Gourmet Popcorn with a new age twist',
        description : 'Indulge in Pure Bliss: Explore our range of irresistibly Crunchy Caramel Popcorn for the Ultimate Treat Experience!',
        productImage : 'assets/img/banner_popcorn.png',
        benefits: ["Premium Ingredients for Superior Taste",
                    "Healthy Snacking Alternative",
                    "Unique and Bold Flavor Selection",
                    "All-Natural and High-Quality Ingredients",
                    "Perfect for Any Occasion",
                    "Guilt-Free Indulgence",
                    "Crafted with Care"],
        items : [
            { id : 21, name : 'Chocolate Caramel Popcorn', image : 'assets/img/items/popcorn/pc_choco.png',
                description: "Indulge in the luxurious fusion of sweet and rich flavors with our Chocolate Caramel Popcorn. Made from premium American corn, this gourmet treat blends the irresistible smoothness of creamy chocolate with the buttery sweetness of caramel . Each kernel is generously coated to deliver a delightful crunch and a melt-in-your-mouth experience. Perfect for those who want to satisfy both their sweet tooth and their cravings for a light snack, this popcorn is the ultimate indulgence for any occasion. Whether it's a movie night or an afternoon pick-me-up, Why Not Foods’ Chocolate Caramel Popcorn is your go-to sweet escape.",
                gallery: ['',
                        '',
                        '',
                        '',
                        ''
                    ],
                ingredients: [{item: 'Energy (kcal)', serving: '118', per: ''},
                              {item: 'Protein (g)', serving: '2.10g', per: '4'},
                              {item: 'Carbohydrates (g) ', serving: '24.0g', per: '9'},
                              {item: 'Total Sugar (g)', serving: '12.4g', per: ''},
                              {item: 'Added Sugars (g)', serving: '12.4g', per: '25'},
                              {item: 'Total Fat (g)', serving: '1.50g', per: '2'},
                              {item: 'Saturated Fat (g)', serving: '0.36g', per: '2'},
                              {item: 'Trans Fat (g)', serving: '0g', per: ''},
                              {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                              {item: 'Sodium (mg)', serving: '17.0mg', per: '1'},
                ]},
            { id : 22, name : 'Classic Caramel Popcorn', image : 'assets/img/items/popcorn/pc_classic.png',
                description: "For those who appreciate timeless flavors, our Classic Caramel Popcorn is the perfect choice. Made from top-quality American corn, each kernel is enveloped in a golden, buttery caramel coating that delivers the perfect crunch with every bite. The simplicity of our classic caramel recipe showcases the richness and warmth of this all-time favorite, offering a pure, indulgent experience that’s both nostalgic and satisfying. Whether you’re enjoying it solo or sharing with friends, this caramel-coated delight never fails to please. Why Not Foods invites you to savor a truly iconic snack, lovingly crafted for caramel popcorn enthusiasts.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '114', per: ''},
                                    {item: 'Protein (g)', serving: '2.05g', per: '4'},
                                    {item: 'Carbohydrates (g) ', serving: '24.0g', per: '9'},
                                    {item: 'Total Sugar (g)', serving: '10.92g', per: ''},
                                    {item: 'Added Sugars (g)', serving: '10.92g', per: '22'},
                                    {item: 'Total Fat (g)', serving: '1.15g', per: '1'},
                                    {item: 'Saturated Fat (g)', serving: '0.26g', per: '1'},
                                    {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                    {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                    {item: 'Sodium (mg)', serving: '21.0mg', per: '1'},
                        ]},
            { id : 23, name : 'Cheese Caramel Popcorn', image : 'assets/img/items/popcorn/pc_cheese.png',
                description: "Discover the bold and unexpected pairing of savory and sweet with our Cheese Caramel Popcorn . Combining the finest American corn kernels, we coat half in rich, buttery caramel and the other half in savory, tangy cheese . This unique flavor combination creates an exciting contrast of creamy sweetness and sharp cheesiness in every bite. Whether you're a fan of sweet or savory snacks, this gourmet popcorn offers the best of both worlds, making it perfect for those who can’t decide between indulgence and satisfaction. Why Not Foods' Cheese Caramel Popcorn delivers an unforgettable snacking experience that leaves you wanting more.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '112', per: ''},
                                    {item: 'Protein (g)', serving: '2.0g', per: '4'},
                                    {item: 'Carbohydrates (g) ', serving: '23.46g', per: '8'},
                                    {item: 'Total Sugar (g)', serving: '11g', per: ''},
                                    {item: 'Added Sugars (g)', serving: '11g', per: '22'},
                                    {item: 'Total Fat (g)', serving: '1.14g', per: '1'},
                                    {item: 'Saturated Fat (g)', serving: '0.30g', per: '1'},
                                    {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                    {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                    {item: 'Sodium (mg)', serving: '37.0mg', per: '2'},
                                    ]},
            { id : 24, name : 'Salted Caramel Popcorn', image : 'assets/img/items/popcorn/pc_salted.png',
                description: "Experience the classic allure of Salted Caramel Popcorn , where the finest American corn is coated in rich, buttery caramel and perfectly balanced with a sprinkle of sea salt . This irresistible combination creates a harmony of sweet and salty flavors, making every handful a moment of bliss. Crafted to satisfy both cravings, our Salted Caramel Popcorn offers a gourmet snacking experience that elevates the ordinary. Whether you’re looking for a satisfying movie snack or a delicious treat to share, this premium popcorn delivers indulgence and comfort in every crunchy bite. Because at Why Not Foods, we ask: Why settle for ordinary?",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '115', per: ''},
                                    {item: 'Protein (g)', serving: '1.95g', per: '4'},
                                    {item: 'Carbohydrates (g) ', serving: '24.20g', per: '9'},
                                    {item: 'Total Sugar (g)', serving: '11.0g', per: ''},
                                    {item: 'Added Sugars (g)', serving: '11.0g', per: '22'},
                                    {item: 'Total Fat (g)', serving: '1.17g', per: '1'},
                                    {item: 'Saturated Fat (g)', serving: '0.25g', per: '1'},
                                    {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                    {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                    {item: 'Sodium (mg)', serving: '135.0mg', per: '6'},
                        ]},
            { id : 25, name : 'Peri Peri Caramel Popcorn', image : 'assets/img/items/popcorn/pc_periperi.png',
                description: "Our Gourmet Popcorn is crafted from the finest American corn kernels, ensuring every bite is irresistibly light and crispy. Infused with bold Peri-Peri Sprinkler seasoning, this snack delivers a perfect balance of fiery spice and tangy zest that’s sure to excite your taste buds. At Why Not Foods, we believe in elevating snacking experiences, and our Peri-Peri Popcorn offers a gourmet twist on a beloved classic. Whether you're enjoying it solo or sharing with friends, this flavor-packed treat is made for those who crave boldness in every bite. Snack smart, snack spicy, with Why Not Foods.",
                gallery: ['',
                          '',
                          '',
                          '',
                          ''
                        ],
                        ingredients: [{item: 'Energy (kcal)', serving: '112', per: ''},
                                    {item: 'Protein (g)', serving: '2.05g', per: '4'},
                                    {item: 'Carbohydrates (g) ', serving: '23.37g', per: '8'},
                                    {item: 'Total Sugar (g)', serving: '10.8g', per: ''},
                                    {item: 'Added Sugars (g)', serving: '10.8g', per: '22'},
                                    {item: 'Total Fat (g)', serving: '1.10g', per: '1'},
                                    {item: 'Saturated Fat (g)', serving: '0.27g', per: '1'},
                                    {item: 'Trans Fat (g)', serving: '0g', per: ''},
                                    {item: 'Cholestrol (mg)', serving: '0g', per: '0'},
                                    {item: 'Sodium (mg)', serving: '93.0mg', per: '4'},
                        ]},
        ]
    },
    // {
    //     productId : 3,
    //     productType : 'Fox Nuts',
    //     title : 'Why Not Fox Nuts',
    //     subtitle : 'Redefining Healthy Eating',
    //     description : 'Crunch into Delight: Fox Nuts That Redefine Snacking with Every Irresistible Bite!',
    //     items : [
    //         { id : 31, name : 'Mexican Cheese Salsa Dosa', image : ''},
    //         { id : 32, name : 'Noodle Dosa', image : ''},
    //         { id : 33, name : 'Butter Dosa', image : ''},
    //         { id : 34, name : 'Cheese Dosa', image : ''},
    //         { id : 35, name : 'Spicy Schezwan Dosa', image : ''},
    //     ]
    // }
  ]

  const occassions = [{ title: "Movie Nights and Binge-Watching Sessions", image: 'assets/img/occassions/movie_nights.png'},
                        { title: "Parties and Social Gatherings", image: 'assets/img/occassions/party_and_gatherings.png'},
                        { title:  "On-the-Go Snacking", image: 'assets/img/occassions/on_the_go.png'},
                        { title:  "Festive Celebrations and Gifting", image: 'assets/img/occassions/festivals_and_gifting.png'},
                        { title:  "Midnight Cravings", image: 'assets/img/occassions/midnight_snack.png'}]

  export default {
    products,
    occassions
  }